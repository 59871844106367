import Image from 'next/image';
import React from 'react';
import Link from 'next/link';

import { cx } from '@emotion/css';

interface Props {
  title: string;
  desc: string;
  mainImageSrc: string;
  iconImageSrc: string;
  styles: any;
  href: string;
  width?: number;
}
const ImageComponent = (props: Props) => {
  const {
    styles,
    title,
    desc,
    mainImageSrc,
    iconImageSrc,
    href,
    width = 30,
  } = props;
  return (
    <div className={styles.service_card}>
      <Link href={href}>
        <div className={styles.service_img}>
          <Image
            src={mainImageSrc}
            alt={`${title} image`}
            layout="fill"
            objectFit="cover"
            className={styles.img}
          />
        </div>
        <div className={styles.service_content}>
          <div className={styles.root}>
            <div className={styles.service_icon}>
              <div className={styles.img}>
                <Image
                  src={iconImageSrc}
                  alt={`${title} icon`}
                  height={30}
                  width={width}
                />
              </div>
            </div>
            <h4 className={cx(styles.h4, styles.service_name)}>{title}</h4>
            <p className={cx(styles.p, styles.service_desc)}>{desc}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const Services = ({ styles }: any) => {
  return (
    <section className={cx(styles.sec, styles.service_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div className={styles.heading_blk}>
            <p className={styles.heading_tag}>Services we offer</p>
            <h2 className={cx(styles.h2, styles.rb_g)}>
              How can we
              <br /> help?
            </h2>
          </div>
        </div>
        <div className={cx(styles.row, styles.center, styles.spaceb)}>
          <div className={cx(styles.first_col, styles.service_col)}>
            <ImageComponent
              title="Locksmith"
              href="/services/locksmith"
              desc="Installation and repair of locks, key cutting and emergency lockout assistance."
              mainImageSrc="/images/Locksmith.webp"
              iconImageSrc="/images/propertyManager/services/locksmiths.svg"
              styles={styles}
            />

            <ImageComponent
              title="Carpet Cleaning"
              href="/services/carpet-and-upholstery-cleaning"
              desc="Deep cleaning for Carpets, Upholstery and Rugs plus stain removal"
              mainImageSrc="/images/Carpet_Cleaning.webp"
              width={35}
              iconImageSrc="/images/icons/carpet_cleaning.svg"
              styles={styles}
            />

            <ImageComponent
              title="Heating & gas"
              href="/services/heating-and-gas"
              desc="Installation and repair of all heating systems, gas safety checks and boiler servicing."
              mainImageSrc="/images/boiler.png"
              iconImageSrc="/images/icons/gas-icon.svg"
              styles={styles}
            />

            <ImageComponent
              title="Appliance repair"
              href="/services/appliance-repair"
              desc="Washing machines, dishwashers, electric ovens, dryers, fridges and more."
              mainImageSrc="/images/appliance-repair.jpg"
              iconImageSrc="/images/icons/appliance-icon.svg"
              styles={styles}
            />
          </div>
          <div className={cx(styles.second_col, styles.service_col)}>
            <ImageComponent
              title="Plumbing"
              href="/services/plumbing"
              desc="Repairing cold water issues with leaks, taps, sinks, baths, showers, and toilets."
              mainImageSrc="/images/plumbing.png"
              iconImageSrc="/images/icons/plumbing-icon.svg"
              styles={styles}
            />
            <ImageComponent
              title="Electrical"
              href="/services/electrical"
              desc="Repairs and fault finding for electrical issues such as fuse boxes, lighting, sockets, and wiring."
              mainImageSrc="/images/electrical.jpg"
              iconImageSrc="/images/icons/electrical-icon.svg"
              styles={styles}
            />
            <ImageComponent
              title="Landlord certificates"
              href="/services/landlord-certificate"
              desc="Gas Safety Certificate, EICR, PAT test, and Energy Performance Certificates."
              mainImageSrc="/images/certificate.jpg"
              iconImageSrc="/images/icons/certificate-icon.svg"
              styles={styles}
            />
          </div>
          <div className={cx(styles.third_col, styles.service_col)}>
            <ImageComponent
              title="Drainage"
              href="/services/drainage"
              desc="Clearing blockages to internal or external drains and pipework. "
              mainImageSrc="/images/drainage.jpg"
              iconImageSrc="/images/icons/drainage-icon.svg"
              styles={styles}
            />

            <ImageComponent
              title="Fire Risk Assessment"
              href="/services/fire-risk-assessment"
              desc="Fire Safety Solutions - Fire Risk Assessments, Installation of Fire Doors, Emergency Lighting and Smoke Alarms."
              mainImageSrc="/images/Fire_Risk_Assesment.webp"
              width={45}
              iconImageSrc="/images/icons/fire_assessment.svg"
              styles={styles}
            />

            <ImageComponent
              title="Window & Door"
              href="/services/window-door"
              desc="Everything windows and doors - installation, replacement, repairs including uPVC, double glazed windows and misted glass."
              mainImageSrc="/images/Expert_Window_and_Door_Fitters.webp"
              iconImageSrc="/images/propertyManager/services/glazing-specialists.svg"
              styles={styles}
            />

            <ImageComponent
              title="Handyman"
              href="/services/handyman"
              desc="Odd jobs, furniture assembly, making small general repairs inside the home."
              mainImageSrc="/images/handyman.jpg"
              iconImageSrc="/images/icons/handyman-icon.svg"
              styles={styles}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
