import React from 'react';

import styles from './MarqueeView.module.scss';
import Image from 'next/image';

type MarqueeViewProps = {
  isBackgroundColorRequired?: boolean;
};
export const MarqueeView = (props: MarqueeViewProps) => {
  const { isBackgroundColorRequired } = props;
  return (
    <div
      className={
        isBackgroundColorRequired
          ? styles.trusted_by_industry_color
          : styles.trusted_by_industry
      }>
      <p className={styles.trusted_by_industry_p}>
        Trusted by Industry Leaders
      </p>
      <div className={styles.marquee_header}>
        <div className={styles.marquee_block}>
          <div className={styles.marquee}>
            <div className={styles.marquee__inner}>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-1.svg"
                  alt="sterling ackroyd logo"
                  width={38}
                  height={40}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-2.svg"
                  alt="pass the keys logo"
                  width={179}
                  height={40}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-3.svg"
                  alt="veeve logo"
                  width={142}
                  height={24}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-4.svg"
                  alt="houst logo"
                  width={118}
                  height={28}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-5.svg"
                  alt="home made logo"
                  width={272}
                  height={28}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-6.svg"
                  alt="fraser logo"
                  width={126}
                  height={38}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-7.svg"
                  alt="johns & co logo"
                  width={206}
                  height={24}
                />
              </div>
            </div>

            <div className={styles.marquee__inner} aria-hidden="true">
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-1.svg"
                  alt="sterling ackroyd logo"
                  width={38}
                  height={40}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-2.svg"
                  alt="pass the keys logo"
                  width={179}
                  height={40}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-3.svg"
                  alt="veeve logo"
                  width={142}
                  height={24}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-4.svg"
                  alt="houst logo"
                  width={118}
                  height={28}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-5.svg"
                  alt="home made logo"
                  width={272}
                  height={28}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-6.svg"
                  alt="fraser logo"
                  width={126}
                  height={38}
                />
              </div>
              <div className={styles.mq_data}>
                <Image
                  src="/images/marquee/tr-7.svg"
                  alt="johns & co logo"
                  width={206}
                  height={24}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
